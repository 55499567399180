@import '~antd/dist/antd.less';

@required-color: #f4ba40;
@modal-radius: 12px;

.ant-menu-item {
  span a {
    color: #748494 !important;
  }
}

.ant-menu-active {
  span a {
    color: green !important;
  }
}

.ant-menu-item-selected {
  span a {
    color: #ffff !important;
  }
  .ant-menu-item-icon {
    color: #ffff !important;
  }
}

.ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: -1px 106px 0px 90px;
}

.ant-tabs-bottom .ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-nav::before {
  display: none;
}

.ant-layout-sider-trigger {
  line-height: unset !important;
}

.ant-switch-handle {
  top: -7px;
  left: -1px;
  height: 24px;
  width: 24px;
}

.ant-switch {
  margin-top: 10px;
  height: 10px;
  left: -4px;
}
.ant-switch-handle::before {
  border-radius: 12px;
  border: @primary-color solid 2px;
}

.ant-modal-content {
  border-radius: @modal-radius;
}
.ant-modal-header {
  border-radius: @modal-radius;
}
.ant-modal-body {
  padding: 15px;
}

.ant-upload-list-picture-card-container,
.ant-upload.ant-upload-select-picture-card {
  height: 56px;
  width: 56px;
  margin-bottom: 0;
  margin-right: 5px;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  line-height: 21px !important;
  font-size: 12px;
}

// Border for required
.required {
  border-color: @required-color !important;
}
.required div {
  border-color: @required-color !important;
}
.required svg {
  color: @required-color;
}
.ant-input-disabled,
.ant-input[disabled]:hover {
  border-color: #bfbfbf;
}
.DetailsGraph table {
  width: auto;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-picker-ok .ant-btn-sm {
  height: 30px;
  padding: 0 35px;
}

@primary-color: #030e22;@body-background: #f0f2f5;@border-radius-base: 8px;@font-family: Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;@input-border-color: @primary-color;@input-icon-color: @primary-color;@layout-sider-background: #030e22;@layout-trigger-height: 85px;@layout-trigger-background: #030e22;@menu-dark-bg: #030e22;@menu-dark-item-active-bg: transparent;@menu-dark-selected-item-text-color: red;@modal-header-title-font-size: 30px;@modal-header-bg: @body-background;@modal-header-padding: 18px 18px 0px;@modal-content-bg: @body-background;@picker-border-color: @primary-color;@select-border-color: @primary-color;@tabs-bar-margin: 0px;